/*reset*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

article, aside, details, figcaption, figure, dialog,
footer, header, hgroup, menu, nav, section, main {
    display: block;
}

ul, ul li {
    list-style: none;
}

blockquote, q {
    quotes: none;

    &:before, &:after {
        content: none;
    }
}

a {
    text-decoration: none;
    font-size: 100%;
    color: inherit;
}


table {
    border-collapse: collapse;
    border-spacing: 0;
}

/**
 * Paul Irish box sizing reset so all elements have broder-box.
 */
html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
    font-family: inherit;
}

/**
 * custom smarty resets
 */
a {
    text-decoration: none;
}

button, input, a, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input {
    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

iframe {
    pointer-events: none;
}



body {
    background: linear-gradient(0.13deg, #000000 8.44%, #172424 83.28%);
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 24px;
    color: #fff;
}

.container {
    max-width: 1140px;
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
}

.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
}

header {
    padding: 48px 0 24px;
}

.logo {
    display: inline-block;
    max-width: 99px;

    img {
        width: 100%;
    }
}

main {
    flex: 1;

    .text {
        margin-top: 34px;
    }
}

.page-title {
    font-weight: 900;
    font-size: 48px;
    letter-spacing: 0.08em;
    font-style: italic;
    text-transform: uppercase;
    margin-bottom: 16px;
}
.page-description {
    margin-bottom: 16px;
    font-size: 24px;
    letter-spacing: 0.04em;
    font-weight: 300;
}

.desc {
    margin: 24px 0 48px;
    display: flex;


    &-item {
        display: flex;
        align-items: center;
        text-align: left;
        flex: 1;
        margin-right: 24px;
        background: rgba(255, 255, 255, 0.1);
        padding: 16px 24px;
        border-radius: 10px;

        img {
            margin-right: 16px;
        }

    }
    &-text {
        font-size: 14px;
        margin-top: 4px;
    }
}
.btn-wrapper {
    position: relative;
    text-align: center;
    padding-bottom: 24px;
}

.mint-content {
    display: inline-flex;
    align-items: center;
    color: #000;
    font-family: 'Lato', sans-serif;

}
.mint-input {
    line-height: 44px;
    //background-color: #fff;
    color: #fff;
    border: 1px solid #fff;
    font-weight: 700;
    width: 140px;
    margin-right: 24px;
    font-size: 16px;
    display: flex;
    justify-content: space-around;

    button {
        background: transparent;
        border: none;
        cursor: pointer;

        &[disabled] {
            pointer-events: none;
            opacity: 0.4;
        }

        img {
            display: block;
        }
    }
}

.btn {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 48px;
    line-height: 44px;
    padding: 0 36px;
    background-color: #fff;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 16px;
    transition: .3s;
    color: #28534D;

    &[disabled] {
        pointer-events: none;
        opacity: 0.5;
    }

    &:hover {
        background-color: #E4E3E3;
    }
}

.warn-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 0;
    font-size: 12px;
}

.gallery {
    height: 400px;
    overflow: hidden;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        height: 90%;
        width: 100%;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 96.8%);
    }

    &-row {
        display: flex;
        justify-content: center;

        li {
            margin-right: 24px;
            min-width: 262px;
        }
        img {
            width: 100%;
            display: block;
        }

        &-1 {
            margin-left: -100px;
            margin-bottom: 24px;
        }
    }


}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 24px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 3px solid black;
    border-radius: 50%;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: black transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@media (max-width: 991px) {

    .page-title {
        font-size: 32px;
    }
    .page-description {
        font-size: 18px;
        line-height: 26px;
    }

    .mint-input {
        margin-right: 16px;
    }
    .btn {
        padding: 0 24px;
    }
    .desc {
        flex-direction: column;
        margin: 12px 0 32px;

        &-item {
            margin-right: 0;


            &:not(:last-child) {
                margin-bottom: 16px;
            }

        }
        &-text {
            font-size: 14px;
            margin-top: 4px;
        }
    }

    .gallery {
        height: 272px;

        &:after {
            height: 80%;
        }

        &-row {

            li {
                margin-right: 16px;
                min-width: 188px;
            }

            &-1 {
                margin-bottom: 16px;
            }
        }
    }
}


@media (max-width: 575px) {

    .gallery {
        height: 217px;

        &:after {
            height: 80%;
        }

        &-row {

            li {
                margin-right: 12px;
                min-width: 147px;
            }

            &-1 {
                margin-bottom: 12px;
            }
        }
    }

}

@keyframes ldsRing {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
